import React from 'react'
import { HashRouter, Route, Switch, } from 'react-router-dom'
import './App.scss'

const MainLayout = React.lazy(() => import('./containers'))

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>

class App extends React.Component {
  render() {
    return (
      <>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/" name="Home" render={props => <MainLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>
    )
  }
}

export default App